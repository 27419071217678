<template>
  <div class="badges">
    <span class="label label-warning" v-if="video.setting.exclusive">
        {{ $t("video.setting_exclusive") }}
    </span>
    <span class="label label-info" v-if="video.setting.productPlacement">
        {{ $t("video.setting_product_placement") }}
    </span>
    <span class="label label-danger" v-if="video.setting.forAdults">
        {{ `18+` }}
    </span>
    <span class="label label-info" v-if="video.setting.noAds">
        {{ $t("video.setting_no_ads") }}
    </span>
  </div>
</template>
<script>
export default {
  name: 'VideoSettingInfoBadge',
  props: {
    video: {
      type: Object,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
.badges {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  gap: rem(5px);
  padding-top: 0.5rem;
  padding-right: 0.5rem;
}
</style>
