<template>
  <section>
    <VideoFilter />
    <div v-if="autoRefresh">
      <Autoloader
        v-if="currentPage.length > 0"
        :callback="searchVideos"
        :height="2"
        :interval="60"
      />
    </div>
    <Pagination2
      class="video-pagination"
      :current-page="page"
      :current-limit="limit"
      :page-count="pageCount"
      :total-count="totalCount"
      :default-limit="LIMIT"
      :page-change-handler="pageChanged"
      :limit-change-handler="limitChanged"
      :disabled="Boolean(animatePageShift)"
    />
    <Preloader class="preloader" v-show="callingAPI && !animatePageShift" />
    <div class="pages">
      <!-- render 'items' for 'prevPage' and 'nextPage' only during animation
           this fixes problem with 'display: flex' on the last page
           when there are less items
       -->
      <VideoListTable
        :class="`prev-page prev-page-shift-${animatePageShift}`"
        :videos="animatePageShift ? prevPage : []"
        :show-meta="showMeta"
      />
      <VideoListTable
        :class="`current-page current-page-shift-${animatePageShift}`"
        :videos="currentPage"
        :show-meta="showMeta"
      />
      <VideoListTable
        :class="`next-page next-page-shift-${animatePageShift}`"
        :videos="animatePageShift ? nextPage : []"
        :show-meta="showMeta"
      />
    </div>
    <Pagination2
      class="video-pagination"
      :current-page="page"
      :current-limit="limit"
      :page-count="pageCount"
      :total-count="totalCount"
      :default-limit="LIMIT"
      :page-change-handler="pageChanged"
      :limit-change-handler="limitChanged"
      :disabled="Boolean(animatePageShift)"
    />
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import VideoFilter from '../../components/video/VideoFilter'
import Autoloader from '@/components/Autoloader'
import VideoListTable from '@/components/video/VideoListTable'
import Pagination2 from '@/components/Pagination2'
import PermissionService from '@/services/PermissionService'
import Preloader from '@/components/preloader/Preloader'

export default {
  name: 'VideoListView',
  data () {
    return {
      autoRefresh: true,
      dataLoaded: false,
      animatePageShift: '',
      showMeta: true,
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.VIDEO_PERMISSIONS,
      LIMIT: 20
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    ...mapGetters('video', [
      'prevPage',
      'currentPage',
      'nextPage',
      'limit',
      'totalCount',
      'page'
    ]),
    isCreateButtonVisible () {
      return this.hasPermission(this.requiredPermissions.createButton)
    },
    isEditButtonVisible () {
      return this.hasPermission(this.requiredPermissions.editButton)
    },
    pageCount () {
      return Math.ceil(this.totalCount / this.limit)
    }
  },
  components: {
    VideoFilter,
    Autoloader,
    VideoListTable,
    Pagination2,
    Preloader
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    searchVideos () {
      this.$store.dispatch('video/fetchPage')
    },

    async fetchPage (page) {
      this.$store.commit('video/setPage', page)
      await this.$store.dispatch('video/fetchPage')
      this.animatePageShift = ''
    },
    pageChanged (page) {
      let animate = false
      if (page === this.page - 1) {
        this.animatePageShift = 'right'
        animate = true
      } else if (page === this.page + 1) {
        this.animatePageShift = 'left'
        animate = true
      }
      if (animate) {
        setTimeout(() => {
          this.fetchPage(page)
          // animation is set to 700ms below in <style>
          // keep this at 600ms to prevent display flashing due to rerendering
        }, 600)
      } else {
        this.fetchPage(page)
      }
    },
    limitChanged (limit) {
      this.$store.commit('video/setPage', 1)
      this.$store.commit('video/setLimit', limit)
      this.$store.dispatch('video/fetchPage')
    }
  },
  mounted () {
    this.$store.commit('video/setTabIndex', 0)
  },
  beforeRouteEnter (to, from, next) {
    next(thisComponent => {
      if (!from.path.includes('video')) {
        thisComponent.$store.commit('video/setPage', 1)
      }
      thisComponent.searchVideos()
    })
  }
}
</script>

<style lang="scss" scoped>
.video-pagination {
  padding-right: rem(10px);
}

.pages {
  display: flex;
}
.prev-page {
  flex: 0 0 100%;
  position: relative;
  left: -105%;
}
.current-page {
  flex: 0 0 100%;
  position: relative;
  left: -100%;
}
.next-page {
  flex: 0 0 100%;
  position: relative;
  left: -95%;
}
.next-page-shift-left {
  position: relative;
  animation: next-page-shift-left 700ms;
}
.current-page-shift-left {
  position: relative;
  animation: current-page-shift-left 700ms;
}
.prev-page-shift-right {
  position: relative;
  animation: prev-page-shift-right 700ms;
}
.current-page-shift-right {
  position: relative;
  animation: current-page-shift-right 700ms;
}

@-webkit-keyframes next-page-shift-left {
  0% {
    left: -100%;
  }
  100% {
    left: -200%;
  }
}
@-webkit-keyframes current-page-shift-left {
  0% {
    left: -100%;
  }
  100% {
    left: -205%;
  }
}
@-webkit-keyframes prev-page-shift-right {
  0% {
    left: -100%;
  }
  100% {
    left: 0%;
  }
}
@-webkit-keyframes current-page-shift-right {
  0% {
    left: -100%;
  }
  100% {
    left: 5%;
  }
}
</style>
